import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import UploadPhoto from './components/UploadPhoto';
import UpGrauPrimer from './components/UpGrauPrimer';
import UpGrauSegonTercer from './components/UpGrauSegonTercer';
import UpDocProfe from './components/UpDocProfe';
import Home from './components/Home';


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/upload" element={<UpDocProfe />} />

          <Route element={
            <div><h1>Página no encontrado 404</h1></div>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
