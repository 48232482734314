import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig/firebase";

function UpDocProfe() {
  const query = new URLSearchParams(window.location.search);
  const id = query.get("id");

  const [ejecutado, setEjecutado] = useState(false);
  const [contactId, setContactId] = useState(id);
  const [docuEnviada, setDocuEnviada] = useState([]);
  const [idValor, setIdValor] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload(true);
  };

  useEffect(() => {
    if (!ejecutado) {
      // Aquí colocas la función que deseas ejecutar solo la primera vez
      console.log("La función se ejecuta solo la primera vez");
      try {
        setDoc(doc(db, "sf-id", id), {
          id,
        });
      } catch (error) {
        console.log(error);
      }

      setEjecutado(true);
    }
  }, [ejecutado]);


  useEffect(() => {
    const getLista = async () => {
      try {
        const docRef = doc(db, "sf-id", id);
        const docSnap = await getDoc(docRef);
        console.log(docSnap.data().id);
        setDocuEnviada(docSnap.data());
        if (docSnap.data().id === true) {
          setIdValor(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getLista();
  }, [docuEnviada]);

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  if (contactId === null) {
    return (
      <Modal show="true" >
        <Modal.Header><strong>ERROR</strong></Modal.Header>
        <Modal.Body>
          Estás usando un enlace al que le falta información!
        </Modal.Body>
      </Modal>
    );
  }

  if (idValor === true) {
    return (
      <Modal show="true">
        <Modal.Header><strong>COMPLETADO</strong></Modal.Header>
        <Modal.Body>Ja has enviado toda documentación </Modal.Body>
      </Modal>
    );
  }

  const enviar = async (values) => {
    var date = new Date().getTime();
    date = date.toString();

    // --- DOCUMENTO 1 ---
    const base1 = await convertBase64(values.file1);

    const dac1 = base1.split(",")[1];
    const photo1 = dac1.toString();
    const format1 = base1.split(",")[0];
    const e1 = format1.split("/")[1];
    const ext1 = e1.split(";")[0];

    const id = contactId;

    const arrPhoto = [];

    arrPhoto.Title = "Document DNI";
    arrPhoto.VersionData = photo1;
    arrPhoto.PathOnClient = id + "." + ext1;
    arrPhoto.description = "Profile Picture";
    arrPhoto.FirstPublishLocationId = id;
    console.log(arrPhoto);

    try {
      await setDoc(doc(db, "test_v1", date + 1), {
        ...arrPhoto,
      });
      console.log("Sended");
      setShow(true);
    } catch (error) {
      console.log(error);
    }
    // --- FI DOCUMENTO 1 ---
  };

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg"];
  const DNI_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];

  const DNI_SIZE = 800000; //100KB  102400 Bytes
  const FILE_SIZE = 800000;

  // * SCHEMA * //
  const schema = yup.object().shape({
    file1: yup
      .mixed()
      .test(
        "fileFormat",
        "El formato del archivo debe ser jpg",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      )
      .test(
        "fileSize",
        "Tamaño del archivo demasiado grande",
        (value) => value && value.size <= FILE_SIZE
      )
      .required("No has seleccionado ningun archivo"),
  });

  return (
    <>
      <Container>
        <div className="mt-0 mt-lg-4 p-3 p-lg-0 p-md-0 p-sm-0 mt-sm-2 border-bottom border-2 border-dark mb-4">
          <h2 className="m-0 fw-bold ">Documentació Profesor</h2>
        </div>

        <Formik
          validationSchema={schema}
          onSubmit={enviar}
          initialValues={{
            file1: null,
          }}
          validateOnMont
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            setFieldValue,
          }) => (
            <Form className="p-2" noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="doc1" className="mb-3">
                <Form.Label className="form-label mb-0">
                  <h4>Fotocopia DNI</h4>
                  <p className="mb-0">
                  Para completar el proceso, necesitamos que nos hagas llegar una fotocopia de tu Documento Nacional de Identidad (DNI). Por favor, sube la fotocopia de tu DNI.
                  </p>
                </Form.Label>
                <Form.Control
                  type="file"
                  name="doc"
                  className="form-control border-dark  ps-1 pb-1 fw-bold"
                  onChange={(event) => {
                    setFieldValue("file1", event.currentTarget.files[0]);
                  }}
                  isValid={!errors.file1}
                  isInvalid={!!errors.file1}
                />
                {errors.file1 ? (
                  <div className="p-2">
                    <h6 className="text-secondary">{errors.file1}</h6>
                  </div>
                ) : null}
              </Form.Group>

              <Button
                className=""
                variant="dark"
                type="submit"
                value="Subir"
                as="input"
                disabled={!isValid}
              ></Button>
            </Form>
          )}
        </Formik>

        <Modal show={show}>
          <Modal.Header><strong>¡Muchas gracias!</strong></Modal.Header>
          <Modal.Body> Datos enviados correctamente.</Modal.Body>
        </Modal>
      </Container>
    </>
  );
}

export default UpDocProfe;
